class Galeria {
    constructor(configurations = {}) {

        if(configurations.container) {
            this.container = document.querySelector(configurations.container);
        } else {
            console.error('Você precisa passar um ID do container para a galeria');
            return;
        }

        if(configurations.imagens) {
            this.imagens = document.querySelectorAll(configurations.imagens);
        } else {
            console.error('Você precisa passar um ID das imagens para a galeria');
            return;
        }

        this.index = 0;
        if(configurations.current) {
            this.index = configurations.current;
        }

        if(this.index < 0 || this.index > this.imagens.length){
            this.index = 0;
        }
    }

    init() {
        let gallery_fullscreen = document.getElementById('galeria_fullscreen');
        
        if(this.index > 0){
            this.index--;
        }
        if(this.index < 0) {
            this.index = this.imagens.length - 1;
        }
        this.imagens.forEach((imagem) => {
            imagem.style.display = 'none';
        });

        this.imagens[this.index].style.display = 'block';

        gallery_fullscreen.classList.remove('hidden');
        document.body.style.overflow = 'hidden';
    }

    set current(current) {
        this.index = current;
    }

    back(){

        this.imagens[this.index].style.display = 'none';
        this.index--;
        if(this.index < 0) {
            this.index = this.imagens.length - 1;
        }
        this.imagens[this.index].style.display = 'block';
    }

    next(){
        
        this.imagens[this.index].style.display = 'none';
        this.index++;
        if(this.index >= this.imagens.length) {
            this.index = 0;
        }
        this.imagens[this.index].style.display = 'block';
    }
};